/* and layout override start */

.ant-bg-gold-2 {
    background: #fff1b8;
}

.ant-layout-content {
    background: #fff;
    margin: 20px 14px;
    padding: 16px;
}

.ant-layout-sider .bottom-menu {
    position: fixed;
    max-width: 200px;
    bottom: 52px;
}

.ant-layout-sider .bottom-menu.ant-menu-inline-collapsed {
    width: 80px !important;
}

.ant-layout-sider .logo {
    text-align: center;
    height: 40px;
    margin: 25px 5px;
}

.ant-layout-sider .logo img {
    height: 100%;
}

.ant-layout-sider .logo img:last-child {
    margin-left: 10px;
    opacity: 1;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
        width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
}

.ant-layout-sider.ant-layout-sider-collapsed .logo img:last-child {
    display: inline-block;
    max-width: 0;
    opacity: 0;
    margin-left: 0px;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #273379;
}

.side-menu {
    max-height: calc(100vh - 250px);
    overflow-y: scroll;
    /* Hide scrollbar for IE, Edge and Firefox */
    /*-ms-overflow-style: none;  IE and Edge */
    /*scrollbar-width: none;  Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
/* .side-menu::-webkit-scrollbar {
    display: none;
} */

.ant-page-header {
    padding: 0 0 12px 0;
}

/* and layout override end */

/* ant tabs override start */

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: normal;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add {
    height: 40px;
}

/* ant tabs override end */

/* ant icon override start */

.ant-btn > .anticon,
.ant-menu-item > .anticon,
.ant-table .ant-table-column-sorter-inner > .anticon,
.ant-pagination-item-link > .anticon,
.ant-table-cell-with-append > .anticon,
.ant-tabs-nav-add > .anticon,
.ant-dropdown-menu-item > .anticon,
.ant-select-clear > .anticon {
    display: inline-flex !important;
}

/* ant icon override end */

/* ant table override start */

.ant-table .ant-btn-link {
    padding: 0;
    height: 22px;
}

.ant-table-tbody > tr.ant-table-row > td.ant-table-cell-dark {
    background-color: #f5f5f5 !important;
}

.ant-table-tbody > tr.ant-table-row > td.ant-table-cell-fixed {
    background-color: #fafafa !important;
}

.ant-table-tbody > tr.ant-table-row.no-hover:hover > td {
    background: unset;
}

.ant-table .ant-table-summary {
    background-color: #fafafa;
}

/* ant table override end */

/* ant table cell overlay start */

.ant-table-tbody > tr.ant-table-row > .data-cell {
    position: relative;
}

.ant-table-tbody > tr.ant-table-row > .data-cell:hover .content {
    opacity: 0;
}

.ant-table-tbody > tr.ant-table-row > .data-cell:hover .overlay {
    opacity: 1;
}

.ant-table-tbody > tr.ant-table-row > .data-cell > .overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background: #eeeceb;
    transition: 0.5s ease;
    opacity: 0;
}

/* ant table cell overlay end */

/* ant table slim start */

.ant-table-slim .ant-table {
    font-size: 12px;
}

.ant-table-slim .ant-table.ant-table-empty:not(.ant-table-fixed-header) .ant-table-tbody {
    display: none;
}

.ant-table-slim .ant-table .ant-input-number {
    width: 100%;
}

.ant-table-slim .ant-table .ant-input-number,
.ant-table-slim .ant-table .ant-select-selector {
    font-size: 12px;
}

.ant-table-slim .ant-table .ant-input,
.ant-table-slim .ant-table .ant-input-number-input {
    padding: 8px;
    font-size: 12px;
}

.ant-table-slim .ant-table .ant-input-number-input {
    height: 34px;
    text-align: right !important;
}

.ant-table-slim .ant-table .ant-input-number-handler-wrap {
    display: none;
}

.ant-table-slim .ant-table .ant-table-tbody > tr > td,
.ant-table-slim .ant-table tfoot > tr > td {
    padding: 2px 4px;
}

.ant-table-slim .ant-table tfoot > tr > td {
    border-top: 1px solid rgba(0, 0, 0, 0.85);
}

.ant-table-slim .ant-table .ant-select-single .ant-select-selector {
    height: 36px !important;
}

.ant-table-slim
    .ant-table
    .ant-select-single
    .ant-select-selector
    .ant-select-selection-placeholder,
.ant-table-slim .ant-table .ant-select-single .ant-select-selector .ant-select-selection-item {
    line-height: 34px;
}

.ant-table-slim
    .ant-table
    .ant-select-single
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 34px;
}

.ant-table-slim .ant-table .ant-form-item {
    margin-bottom: 0;
}

.ant-table-slim .ant-table .ant-form-item .ant-form-item-explain.ant-form-item-explain-error {
    font-size: 12px;
}

.ant-table-slim .ant-table .ant-table-thead > tr > th {
    border-bottom: 1px solid rgba(0, 0, 0, 0.85);
}

.ant-table-slim .ant-table .ant-table-thead > tr > th.border-right::before {
    width: 0 !important;
}

.ant-table-slim
    .ant-table
    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
        [colspan]
    )::before {
    background-color: rgba(0, 0, 0, 0.85);
}

/* ant table slim end */

/* ant table filter start */

/* .ant-table-filter-dropdown .ant-btn {
    width: 32px !important;
    height: 32px !important;
} */

/* ant table filter end */

/* ant colors start */

.blue-6 {
    color: #1890ff;
}

.green-6 {
    color: #52c41a;
}

.red-5 {
    color: #ff4d4f;
}

.yellow-6 {
    color: #fadb14;
}

/* ant colors end */

/* sidebar start */

::-webkit-scrollbar {
    width: 10px;
    height: 8px;
}

::-webkit-scrollbar-track {
    /*box-shadow: inset 0 0 2px grey;*/
}

::-webkit-scrollbar-thumb {
    background: #1890ff;
}

/* sidebar end */

/* misc start */

.scrollable-content {
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: calc(100vh - 153px);
    padding-right: 30px;
}

.border-left {
    border-left: 1px solid rgba(0, 0, 0, 0.85);
}

.border-right {
    border-right: 1px solid rgba(0, 0, 0, 0.85);
}

.margin-right-10 {
    margin-right: 10px;
}

/* misc end */

/* print start */

@media print {
    .no-print,
    .no-print * {
        display: none !important;
    }
}

/* print end */

/* checkbox group start */

.ant-checkbox-group.vertical .ant-checkbox-group-item {
    width: 100%;
}

/* checkbox group end */

/* radio group start */

.ant-radio-group.vertical .ant-radio-wrapper {
    width: 100%;
}

/* radio group end */

/* notifications group start */

.notification-overflow-container {
    height: 19vh;
}

.notification-overflow {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;

    /* Firefox */
    line-height: -moz-calc(100vh / 35);
    /* WebKit */
    line-height: -webkit-calc(100vh / 35);
    /* Opera */
    line-height: -o-calc(100vh / 35);
    /* Standard */
    line-height: calc(100vh / 35);
}

@media screen and (max-height: 790px) {
    .notification-overflow {
        -webkit-line-clamp: 3;
        /* Firefox */
        line-height: -moz-calc(100vh / 30);
        /* WebKit */
        line-height: -webkit-calc(100vh / 30);
        /* Opera */
        line-height: -o-calc(100vh / 30);
        /* Standard */
        line-height: calc(100vh / 30);
    }
}

@media screen and (max-height: 640px) {
    .notification-overflow {
        -webkit-line-clamp: 2;
        /* Firefox */
        line-height: -moz-calc(100vh / 25);
        /* WebKit */
        line-height: -webkit-calc(100vh / 25);
        /* Opera */
        line-height: -o-calc(100vh / 25);
        /* Standard */
        line-height: calc(100vh / 25);
    }
}

/* notifications group end */

.ant-table-content {
    overflow: auto;
}

.ant-table-content th {
    white-space: nowrap;
}

/* page header responsivity */

.ant-page-header > .ant-page-header-heading {
    flex-wrap: wrap-reverse;
}

.disable-ant-tabs-nav-operations .ant-tabs-nav-operations {
    display: none !important;
}